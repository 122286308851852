import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/demo/",
    name: "Demo",
    redirect: "/",
    component: () => import(/* webpackChunkName: "demo" */ "../views/Demo.vue"),
    children: [
      {
        path: "number-1",
        name: "Number-1",
        meta: {
          description: "Cannon test",
        },
        component: () =>
          import(
            /* webpackChunkName: "number-1" */ "../views/demos/number-1/Number-1.vue"
          ),
      },
      {
        path: "number-2",
        name: "Number-2",
        meta: {
          description:
            "WASD/Arrow keys to move object.<br>Mouse to move camera.",
        },
        component: () =>
          import(
            /* webpackChunkName: "number-2" */ "../views/demos/number-2/Number-2.vue"
          ),
      },
      {
        path: "number-3",
        name: "Number-3",
        meta: {
          description: "Use Mouse to move/zoom camera.",
        },
        component: () =>
          import(
            /* webpackChunkName: "number-3" */ "../views/demos/number-3/Number-3.vue"
          ),
      },
      {
        path: "number-4",
        name: "Number-4",
        meta: {
          description: "Floor & Animas.<br>Drag & Zoom.",
        },
        component: () =>
          import(
            /* webpackChunkName: "number-4" */ "../views/demos/number-4/Number-4.vue"
          ),
      },
      {
        path: "number-5",
        name: "Number-5",
        meta: {
          description: "UV Tunnel effect",
        },
        component: () =>
          import(
            /* webpackChunkName: "number-5" */ "../views/demos/number-5/Number-5.vue"
          ),
      },
      {
        path: "number-6",
        name: "Number-6",
        meta: {
          description: "Flowmap",
        },
        component: () =>
          import(
            /* webpackChunkName: "number-6" */ "../views/demos/number-6/Number-6.vue"
          ),
      },
      {
        path: "number-7",
        name: "Number-7",
        meta: {
          description:
            "UV Morphing.<br>Hold Left Mouse Button/hold tap to switch.",
        },
        component: () =>
          import(
            /* webpackChunkName: "number-7" */ "../views/demos/number-7/Number-7.vue"
          ),
      },
      {
        path: "number-8",
        name: "Number-8",
        meta: {
          description: "Light texture baking.",
        },
        component: () =>
          import(
            /* webpackChunkName: "number-8" */ "../views/demos/number-8/Number-8.vue"
          ),
      },
      // {
      //   path: "number-9",
      //   name: "Number-9",
      //   meta: {
      //     description: "Default.",
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "number-9" */ "../views/demos/number-9/Number-9.vue"
      //     ),
      // },
      // {
      //   path: "number-10",
      //   name: "Number-10",
      //   meta: {
      //     description: "Default.",
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "number-10" */ "../views/demos/number-10/Number-10.vue"
      //     ),
      // },
      // {
      //   path: "number-11",
      //   name: "Number-11",
      //   meta: {
      //     description: "Default.",
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "number-11" */ "../views/demos/number-11/Number-11.vue"
      //     ),
      // },
    ],
  },
  {
    path: "/notSupported",
    name: "NotSupported",
    component: () =>
      import(
        /* webpackChunkName: "notSupported" */ "../views/NotSupported.vue"
      ),
  },
  {
    path: "/:catchAll(.*)",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
