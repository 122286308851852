import { reactive /*, readonly*/ } from "vue";

const state = reactive({
  webGLInstance: null,
  assets: null,
  systemInfo: null,
  webcamDIV: null,
  reflectorRTT: null,
  reflectorTextureMatrix: null,
  activeSceneIndex: 0,
  isSceneChanging: null,
  sceneTransitionRatio: null,
  sceneTransitionDuration: 0.5,
  sharedUniforms: {
    u_shadowMapDepthTexture: null,
    u_testTexture: null
  },
});

export default {
  //state: readonly(state),
  state: state,
  actions: {
    addWebGLInstance(_instance) {
      state.webGLInstance = _instance;
    },

    setAssets(_assets) {
      state.assets = _assets;
    },
    setSystemInfo(_systemInfo) {
      state.systemInfo = _systemInfo;
    },
    setWebcamDIV(stream) {
      state.webcamDIV = stream;
    },
    setReflectorRTT(_rtt) {
      state.reflectorRTT = _rtt;
    },
    setReflectorTextureMatrix(_rtt) {
      state.reflectorTextureMatrix = _rtt;
    },
    setActiveSceneIndex(_value) {
      state.activeSceneIndex = _value;
    },
    setIsSceneChanging(_bool) {
      state.isSceneChanging = _bool;
    },
    setSceneTransitionRatio(_value) {
      state.sceneTransitionRatio = _value;
    },
    setSceneTransitionDuration(_value) {
      state.sceneTransitionDuration = _value;
    },
    setSharedUniforms(_uniformKey, _value) {
      state.sharedUniforms[_uniformKey] = _value;
    },
  },
  getters: {
    webGLInstance() {
      return state.webGLInstance;
    },
    assets() {
      return state.assets;
    },
    systemInfo() {
      return state.systemInfo;
    },
    webcamDIV() {
      return state.webcamDIV;
    },
    reflectorRTT() {
      return state.reflectorRTT;
    },
    reflectorTextureMatrix() {
      return state.reflectorTextureMatrix;
    },
    activeSceneIndex() {
      return state.activeSceneIndex;
    },
    isSceneChanging() {
      return state.isSceneChanging;
    },
    sceneTransitionRatio() {
      return state.sceneTransitionRatio;
    },
    sceneTransitionDuration() {
      return state.sceneTransitionDuration;
    },
    sharedUniforms() {
      return state.sharedUniforms;
    },
  },
};
