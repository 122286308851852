<template>
    <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
            <component :is="Component" :key="$route.path" />
        </transition>
    </router-view>
</template>

<script>
import { onMounted } from "vue";
import { shoutout } from "./utils/helpers.js";

export default {
    setup() {
        onMounted(() => {
            shoutout();
        });

        return {};
    },
};
</script>

<style lang="scss">
@import "@/styles//styles.scss";

#app {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
</style>
